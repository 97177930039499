.beta-alert {
  margin-left: 15rem;
  border-radius: 0;
  position: absolute;
  top: 0;
  width: calc(100% - 15rem);

  @media screen and (max-width: 767px) {
    margin-left: 1rem;
    margin-top: 1rem;
    width: calc(100% - 2rem);
  }
}
