.logo {
  font-weight: 600;
  color: #fff;
  text-align: left;
  height: auto;
  margin-bottom: -6px;
  width: 100%;
}

.logo-container {
  @media screen and (max-width: 567px) {
    max-width: 80vw;
  }
}