.popover-z-index-fix {
  z-index: 1090;
  max-width: 15rem !important;
  width: 15rem !important;
}

.dropdown-item {
  padding: 0.375rem 0.5rem;
  color: #6e84a3;
  &:hover {
    color: #000;
  }
}

.check-label {
  display: inline;
  margin-left: 7px;
}
