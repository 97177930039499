@import "./variables";
@import "~@venatus/phoenix-theme/styles/theme.scss";
@import "~@venatus/phoenix-components/dist/styles.css";
@import "flatpickr/dist/flatpickr.css";

// Temporary fix (until header review)
.main-content {
  margin: 1rem 0;
}

// NOTE: Styles to be migrated into theme
.card-pagination.pagination-tabs {
  // TODO: Fix pagination wrapping by hiding
  flex-wrap: wrap;
}

// For file: _react.scss
.is-sorted {
  &::after {
    content: str-replace(
      url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3' fill='#{$text-muted}'/></svg>"),
      "#",
      "%23"
    );
  }
}
.is-sorted-desc {
  &::after {
    content: str-replace(
      url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 10L0 6H6L3 10Z' fill='#{$text-muted}'/></svg>"),
      "#",
      "%23"
    );
  }
}

@media print {
  .print-hidden {
    display: none;
  }
}
